import { Alert } from "@lumar/shared";
import {
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  LinearProgress,
  Typography,
  useTheme,
} from "@material-ui/core";
import { chunk } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { SearchField } from "../../../../../_common/components/SearchField/SearchField";
import { ChooseTestsDispatch } from "../../../../../_common/contexts/Permissions/ChooseTestsDispatch/ChooseTestsDispatch";
import {
  isResolutionWithin,
  ResolutionStep,
} from "../../../../../_common/utils/window/window";
import {
  GetTestSuiteQuery,
  useCreateHealthScoreTestsMutation,
  useDeleteHealthScoreTestsMutation,
  useGetTestSuiteHealthScoreTestsQuery,
  useUpdateHealthScoreAutomaticThresholdMutation,
  useUpdateHealthScoreTestsMutation,
} from "../../../../../graphql";
import { useSaveChangesContext } from "../../../SaveChangesProvider";
import { StickyWrapper } from "../../StickyWrapper/StickyWrapper";
import { ExtendedTest } from "../common/CreateTests.interfaces";
import { areAnyTestsSelected } from "../common/CreateTestsCommonUtils";
import { getTotalShowingTestsCount } from "../components/ChooseTestsFormCountersUtils";
import { ChooseTestsSelectionList } from "../components/ChooseTestsSelectionList";
import { CollapsableTestsList } from "../components/CollapsableTestsList";
import { ChooseTestsFilter } from "../components/filter/ChooseTestFilter";
import { useFilteredReportCategories } from "../utils/useFilteredReportCategories";
import { AutomaticThresholdSettings } from "./AutomaticThresholdSettings";
import {
  getCategories,
  getCreateHealthScoreTestsMutationVariables,
  getUpdateHealthScoreTestsMutationVariables,
  isAnySelectedTestInvalid,
} from "./ChooseHealthScoreTestsFormUtils";
import {
  ChooseHealthScoreTestsAction,
  ChooseHealthScoreTestsState,
} from "./chooseHealthScoreTestsReducer";
import { useStyles } from "./ChooseTestFormStyles";

export interface ChooseHealthScoreTestsFormProps {
  absoluteThresholdUpLimit: number;
  testSuite: NonNullable<GetTestSuiteQuery["node"]>;
  project: NonNullable<GetTestSuiteQuery["getProject"]>;
  onSuccess: () => void;
  useTestSuiteTestsQueryObject: ReturnType<
    typeof useGetTestSuiteHealthScoreTestsQuery
  >;
  hasChildren?: boolean;
  healthScoreSmartThresholdSettings: {
    isEnabled: boolean;
    enabledForAllTests: boolean;
  };
  setHealthScoreSmartThresholdSettings: React.Dispatch<
    React.SetStateAction<{
      isEnabled: boolean;
      enabledForAllTests: boolean;
    }>
  >;
  reducer: [
    ChooseHealthScoreTestsState,
    React.Dispatch<ChooseHealthScoreTestsAction>,
  ];
  disableSelection: boolean;
}

// eslint-disable-next-line max-lines-per-function, max-statements, complexity
export function ChooseHealthScoreTestsForm(
  props: ChooseHealthScoreTestsFormProps,
): JSX.Element {
  const [chooseHealthScoreTestsFormState, chooseHealthScoreTestsFormDispatch] =
    props.reducer;

  const classes = useStyles();
  const theme = useTheme();

  const {
    refetch: refetchTestsAndCustomExtractions,
    data: tests,
    error: testsError,
    loading: testsLoading,
  } = props.useTestSuiteTestsQueryObject;

  const [submitError, setSubmitError] = useState<string | undefined>();
  const [submitting, setSubmitting] = useState<boolean>();
  const [shouldRefreshCategories, setShouldRefreshCategories] =
    useState<boolean>(true);
  const MAX_ALLOWED_GRAPH_QL_CHUNK_SIZE = 99;

  const [createTestsCall] = useCreateHealthScoreTestsMutation();
  const [updateTestsCall] = useUpdateHealthScoreTestsMutation();
  const [deleteTestsCall] = useDeleteHealthScoreTestsMutation();
  const [updateHealthScoreAutomaticThreshold] =
    useUpdateHealthScoreAutomaticThresholdMutation();

  const saveChangesContext = useSaveChangesContext();

  // Original code worked this way.
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
  const testsNode = tests?.node!;

  const {
    data: filteredCategories,
    loading: loadingCategories,
    error: categoriesError,
  } = useFilteredReportCategories(props.testSuite.moduleCode);

  // eslint-disable-next-line complexity
  useEffect(() => {
    function categoriesLoadedOrTestSuiteChangedOrSetToReload() {
      return (
        chooseHealthScoreTestsFormState.categories.length === 0 ||
        shouldRefreshCategories
      );
    }

    if (
      !loadingCategories &&
      !testsLoading &&
      categoriesLoadedOrTestSuiteChangedOrSetToReload() &&
      tests &&
      filteredCategories
    ) {
      setShouldRefreshCategories(false);

      const newCategories = getCategories([...filteredCategories], testsNode);
      chooseHealthScoreTestsFormDispatch({
        type: "SET_CATEGORIES",
        payload: { categories: newCategories },
      });
    }
  }, [
    chooseHealthScoreTestsFormState.categories.length,
    shouldRefreshCategories,
    tests,
    testsLoading,
    testsNode,
    props.testSuite.moduleCode,
    loadingCategories,
    filteredCategories,
    chooseHealthScoreTestsFormDispatch,
  ]);

  useEffect(() => {
    setShouldRefreshCategories(true);
  }, [tests]);

  function createTestsFn(tests: ExtendedTest[]) {
    const testList = tests.filter(
      (test) => !test.data.id && test.extended.selected,
    );
    if (testList.length) {
      const variables = getCreateHealthScoreTestsMutationVariables(
        testList,
        props.testSuite.id,
      );
      return createTestsCall({
        variables,
      });
    }
  }

  function updateTestsFn(tests: ExtendedTest[]) {
    const testList = tests.filter(
      (test) => test.data.id && test.extended.selected,
    );
    if (testList.length) {
      const variables = getUpdateHealthScoreTestsMutationVariables(testList);
      return updateTestsCall({
        variables,
      });
    }
  }

  function deleteTestsFn(tests: ExtendedTest[]) {
    const testList = tests.filter(
      (test) => test.data.id && !test.extended.selected,
    );
    if (testList.length) {
      return deleteTestsCall({
        variables: {
          testIds: testList.map((e) => e.data.id),
        },
      });
    }
  }

  async function updateHealthScoreAutomaticThresholdOnTestSuite() {
    try {
      setSubmitting(true);
      await updateHealthScoreAutomaticThreshold({
        variables: {
          testSuiteId: props.testSuite.id,
          healthScoreAutomaticThreshold:
            props.healthScoreSmartThresholdSettings.isEnabled,
          healthScoreAutomaticThresholdEnabledForAll:
            props.healthScoreSmartThresholdSettings.enabledForAllTests,
        },
        refetchQueries: ["getTestSuite"],
      });
      setSubmitting(false);
    } catch {
      setSubmitting(false);
      setSubmitError(
        "There has been an error while saving data. Please refresh the page and try again.",
      );
    }
  }

  async function resolveTestsChunks(tests: ExtendedTest[][]): Promise<void> {
    try {
      const flatList = tests.reduce<ExtendedTest[]>((r, t) => [...r, ...t], []);
      await createTestsFn(flatList);
      await updateTestsFn(flatList);
      await deleteTestsFn(flatList);
      await updateHealthScoreAutomaticThresholdOnTestSuite();
      setSubmitError(undefined);
      refetchTestsAndCustomExtractions();
      setSubmitting(false);
    } catch (e) {
      refetchTestsAndCustomExtractions();
      setSubmitting(false);
      throw e;
    }
  }

  async function handleSave() {
    if (testsLoading) return;
    const testChunks = chunk(
      chooseHealthScoreTestsFormState.categories.flatMap(
        (category) => category.tests,
      ),
      MAX_ALLOWED_GRAPH_QL_CHUNK_SIZE,
    );

    if (testChunks.length) {
      setSubmitting(true);
      return resolveTestsChunks(testChunks);
    }
  }

  async function handleSubmit() {
    if (
      areAnyTestsSelected(chooseHealthScoreTestsFormState.categories) &&
      isAnySelectedTestInvalid(
        chooseHealthScoreTestsFormState.categories,
        props.absoluteThresholdUpLimit,
      )
    ) {
      setSubmitError(
        "Please fill in all required fields in order to continue.",
      );
      return;
    }

    try {
      await handleSave();
      props.onSuccess();
    } catch {
      setSubmitError(
        "There has been an error while saving data. Please refresh the page and try again.",
      );
    }
  }

  useEffect(() => {
    if (!props.testSuite.parent) {
      saveChangesContext.registerCallback(handleSave, "step3");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chooseHealthScoreTestsFormState]);

  function clearSubmitError() {
    setSubmitError(undefined);
  }

  const filtersContainer = useRef<HTMLDivElement>(null);
  const testsContainer = useRef<HTMLDivElement>(null);
  const [computedMaxHeight, setComputedMaxHeight] = useState<number>(640);

  function calcHeight() {
    const areLocatedNextToEachOther = isResolutionWithin(ResolutionStep.SM);
    if (areLocatedNextToEachOther) {
      const filtersContainerBoundingBox =
        filtersContainer.current?.getBoundingClientRect();
      const testsConteinerBoundingBox =
        testsContainer.current?.getBoundingClientRect();
      if (testsConteinerBoundingBox && filtersContainerBoundingBox) {
        const max = Math.max(
          testsConteinerBoundingBox?.height -
            filtersContainerBoundingBox?.height,
          640,
        );
        setComputedMaxHeight(max);
      }
    } else {
      setComputedMaxHeight(640);
    }
  }
  useEffect(() => {
    window.addEventListener("resize", calcHeight);
    setTimeout(() => {
      calcHeight();
    }, 1000);

    return () => window.removeEventListener("resize", calcHeight);
  }, [chooseHealthScoreTestsFormState.categories]);

  if (loadingCategories || testsLoading) {
    return <CircularProgress data-testid="choose-tests-form-loading" />;
  }

  /* eslint-disable no-nested-ternary */
  return (
    <ChooseTestsDispatch.Provider value={chooseHealthScoreTestsFormDispatch}>
      <Grid container>
        {categoriesError || testsError ? (
          <Alert severity="warning">
            There has been an error while loading data for this view.
          </Alert>
        ) : !loadingCategories &&
          chooseHealthScoreTestsFormState.categories.length > 0 ? (
          <>
            <Grid item xs={12}>
              <div className={classes.padding}>
                <Grid container className={classes.containerStart} spacing={3}>
                  <Grid item sm={12} md={4}>
                    <div className={classes.filterAndControlsWrapper}>
                      <div ref={filtersContainer} id="layoutAffector">
                        <div
                          data-testid="tests-available"
                          style={{ marginBottom: theme.spacing(1) }}
                        >
                          <Typography
                            color="textPrimary"
                            data-cy="available-tests-count"
                            className={classes.totalTestsTitle}
                          >
                            Tests available (
                            {getTotalShowingTestsCount(
                              chooseHealthScoreTestsFormState.filter,
                              chooseHealthScoreTestsFormState.categories,
                            )}
                            )
                          </Typography>
                          <SearchField />
                        </div>
                        <ChooseTestsFilter
                          chooseTestsFormState={chooseHealthScoreTestsFormState}
                          filterToggle={() => {
                            setTimeout(() => {
                              calcHeight();
                            }, 1000);
                          }}
                        />
                      </div>
                      <ChooseTestsSelectionList
                        maxHeight={computedMaxHeight}
                        chooseTestsFormState={chooseHealthScoreTestsFormState}
                        clearSubmitError={clearSubmitError}
                        renderingProgressed={calcHeight}
                        sortTests={false}
                        disableSelection={props.disableSelection}
                      />
                    </div>
                  </Grid>
                  <Grid item sm={12} md={8} ref={testsContainer}>
                    {!props.hasChildren && !props.testSuite.parent && (
                      <AutomaticThresholdSettings
                        smartThresholdSettings={
                          props.healthScoreSmartThresholdSettings
                        }
                        handleSmartThresholdSettingsChange={
                          props.setHealthScoreSmartThresholdSettings
                        }
                      />
                    )}
                    <Typography
                      variant="h5"
                      className={classes.collapsableListHeadingOtherTests}
                    >
                      Tests selected
                    </Typography>
                    <CollapsableTestsList
                      forceNumericThreshold={true}
                      absoluteThresholdUpLimit={props.absoluteThresholdUpLimit}
                      isJiraIntegrationConnected={Boolean(
                        props.testSuite.testSuiteJiraIntegration,
                      )}
                      categories={chooseHealthScoreTestsFormState.categories}
                      clearSubmitError={clearSubmitError}
                      renderingProgressed={() => calcHeight()}
                      smartThresholdSettings={
                        props.healthScoreSmartThresholdSettings
                      }
                    />

                    {submitError && (
                      <Alert
                        data-cy="submit-error"
                        className={classes.submitError}
                        severity="error"
                      >
                        {submitError}
                      </Alert>
                    )}
                  </Grid>
                </Grid>
              </div>
              <Backdrop open={!!submitting} className={classes.backdrop}>
                <CircularProgress
                  data-testid="choose-tests-form-loading-indicator"
                  color="inherit"
                />
              </Backdrop>

              <StickyWrapper>
                <Button
                  disabled={submitting}
                  onClick={handleSubmit}
                  data-testid="choose-tests-form-save"
                  data-cy="choose-tests-form-save"
                  data-pendo="auto-testsuite-edit-add-choose-tests-save-step"
                  variant="contained"
                  color="primary"
                >
                  Save
                </Button>
              </StickyWrapper>
            </Grid>
          </>
        ) : (
          <div className={classes.progresssBar}>
            <LinearProgress key="linear-progress" />
          </div>
        )}
      </Grid>
    </ChooseTestsDispatch.Provider>
  );
  /* eslint-enable no-nested-ternary */
}
