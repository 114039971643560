import {
  GetTestSuiteQuery,
  ModuleCode,
  TestSuiteLocationCode,
} from "../../../../graphql";
import { dummyAuthPassword } from "./ConnectSiteForm";
import { ConnectSiteFormValues } from "./ErrorEffect";

const DEFAULT_USER_AGENT = "googlebot-smartphone";

const DEFAULT_A11Y_USER_AGENT = "chrome-a11y";

function getTestSiteTestUser(
  testSuiteToParse: NonNullable<GetTestSuiteQuery["node"]>,
) {
  return testSuiteToParse.testSiteUsername || "";
}

function getTestSuiteLocation(
  testSuiteToParse: NonNullable<GetTestSuiteQuery["node"]>,
) {
  return testSuiteToParse.location?.code || TestSuiteLocationCode.Default;
}

function getCustomDns(
  testSuiteToParse: NonNullable<GetTestSuiteQuery["node"]>,
) {
  return testSuiteToParse.customDns || [];
}

const userAgentDefaults: Partial<
  Record<ModuleCode, { uaString: string; uaToken: string; uaCode: string }>
> = {
  [ModuleCode.Accessibility]: {
    uaString: "",
    uaToken: "",
    uaCode: DEFAULT_A11Y_USER_AGENT,
  },
  [ModuleCode.Seo]: {
    uaString: "",
    uaToken: "",
    uaCode: DEFAULT_USER_AGENT,
  },
  [ModuleCode.SiteSpeed]: {
    uaString: "",
    uaToken: "",
    uaCode: "lighthouse-mobile",
  },
};

function getEmptyInitialValues(moduleCode: ModuleCode): ConnectSiteFormValues {
  return {
    name: "",
    primaryDomain: "",
    testSiteUsername: "",
    testSiteDomainPass: "",
    location: TestSuiteLocationCode.Default,
    userAgent: {
      string: userAgentDefaults[moduleCode]?.uaString ?? "",
      token: userAgentDefaults[moduleCode]?.uaToken ?? "",
      code: userAgentDefaults[moduleCode]?.uaCode ?? DEFAULT_USER_AGENT,
      userAgentIsMobile: false,
    },
    customDns: [] as NonNullable<
      NonNullable<GetTestSuiteQuery["node"]>["customDns"]
    >,
    rendererCookies: [],
    automaticThreshold: null,
    automaticThresholdEnabledForAll: null,
    healthScoreAutomaticThreshold: null,
    healthScoreAutomaticThresholdEnabledForAll: null,
    moduleCode,
  };
}

function parseTestSuiteToInitialValues(
  testSuiteToParse: NonNullable<GetTestSuiteQuery["node"]>,
  isAuthPasswordSet: boolean,
): ConnectSiteFormValues {
  const isCustom =
    testSuiteToParse.userAgentString || testSuiteToParse.userAgentToken;
  return {
    name: testSuiteToParse.name,
    primaryDomain: testSuiteToParse.primaryDomain,
    testSiteUsername: getTestSiteTestUser(testSuiteToParse),
    testSiteDomainPass: isAuthPasswordSet ? dummyAuthPassword : "",
    location: getTestSuiteLocation(testSuiteToParse),
    userAgent: {
      string: testSuiteToParse.userAgentString || "",
      token: testSuiteToParse.userAgentToken || "",
      code: !isCustom
        ? (testSuiteToParse.userAgent?.code ?? "Custom")
        : "Custom",
      userAgentIsMobile: testSuiteToParse.userAgentIsMobile,
      viewportWidth: testSuiteToParse.viewportWidth ?? undefined,
      viewportHeight: testSuiteToParse.viewportHeight ?? undefined,
    },
    customDns: getCustomDns(testSuiteToParse),
    rendererCookies:
      testSuiteToParse.rendererCookies?.map((cookie) => ({
        name: cookie.name,
        value: cookie.value,
      })) || [],
    automaticThreshold: null,
    automaticThresholdEnabledForAll: null,
    healthScoreAutomaticThreshold: null,
    healthScoreAutomaticThresholdEnabledForAll: null,
    moduleCode: testSuiteToParse.moduleCode,
  };
}

export function getInitialValues(
  testSuiteToParse: NonNullable<GetTestSuiteQuery["node"]> | undefined,
  isAuthPasswordSet: boolean,
  moduleCode?: ModuleCode,
): ConnectSiteFormValues {
  if (testSuiteToParse) {
    return parseTestSuiteToInitialValues(testSuiteToParse, isAuthPasswordSet);
  } else {
    return getEmptyInitialValues(moduleCode ?? ModuleCode.Seo);
  }
}
