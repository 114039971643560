import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
  Grid,
  Hidden,
  Typography,
  makeStyles,
  Divider,
  useTheme,
} from "@material-ui/core";
import { TestSuiteItemLinks } from "../TestSuiteList/test-suite-list/TestSuiteItemLinks";
import { TemplateSettings } from "./components/TemplateSettings/TemplateSettings";
import { useTestSuiteChildrenQuery } from "./graphql/useTestSuiteChildrenQuery";
import { Alert } from "@lumar/shared";
import { UpdateTestSuiteSteps } from "./UpdateTestSuiteSteps";
import { PageContainer } from "../../_common/components/PageContainer/PageContainer";
import { RunTestSuiteNowButton } from "./components/ConnectCiCdTool/components/RunTestSuiteNowButton";
import { SaveChangesProvider } from "./SaveChangesProvider";
import { UpdateTestSuiteLoadingView } from "./UpdateTestSuiteLoadingView";
import { BuildStatusProvider } from "./contexts/BuildStatusProvider/BuildStatusProvider";
import {
  useGetAutomatorReportTemplatesQuery,
  useGetTestSuiteQuery,
} from "../../graphql";

export interface UpdateTestSuiteProps {
  match: {
    params: {
      testSuiteId: string;
    };
  };
}

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    fontSize: theme.typography.pxToRem(23),
    lineHight: theme.typography.pxToRem(34.5),
    fontWeight: 600,
    marginBottom: "24px",
  },
  title: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(17),
    color: theme.palette.grey[800],
    fontWeight: 600,
    padding: "20px 0 19px 22px",
    margin: theme.spacing(0),
  },
  header: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderWidth: "1px",
    borderStyle: "solid",
    position: "relative",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    borderBottom: "none",
    backgroundColor: "#EBEFF3",
    // Note: the following two lines ensure that we have a nice overlap effect with the header and whatever element is directly beneath it
    paddingBottom: 20,
    marginBottom: -20,
  },
  devider: {
    color: theme.palette.grey[300],
    height: "32px",
    width: "1px",
    margin: "0 8px",
  },
  actions: {
    padding: "11px 0 9px 0",
  },
}));

export type StepChangeHandler = (
  event?: React.ChangeEvent<{ value?: unknown }>,
  isExpanded?: boolean,
) => void;

// eslint-disable-next-line max-lines-per-function, max-statements, complexity
export const UpdateTestSuite = withRouter((props: UpdateTestSuiteProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const [expandedStep, setExpandedStep] = useState("");
  const useReportTemplatesObject = useGetAutomatorReportTemplatesQuery({
    variables: { filter: {} },
  });
  const absoluteThresholdUpLimit = 1000000;

  const {
    loading,
    data,
    error,
    refetch: refetchTestSuite,
  } = useGetTestSuiteQuery({
    variables: { id: props.match.params.testSuiteId },
  });

  const {
    data: testSuiteChildrenData,
    loading: childrenLoading,
    error: childrenError,
    fetchMore: fetchMoreChildren,
    isFetchingMore: isFetchingMoreChildren,
  } = useTestSuiteChildrenQuery({
    id: props.match.params.testSuiteId,
    cursor: null,
  });

  const testSuite = data?.node;
  // Original code worked this way.
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
  const project = data?.getProject!;

  const [smartThresholdSettings, setSmartThresholdSettings] = useState({
    isEnabled: testSuite?.automaticThreshold ?? false,
    enabledForAllTests: testSuite?.automaticThresholdEnabledForAll ?? true,
  });

  const [
    healthScoreSmartThresholdSettings,
    setHealthScoreSmartThresholdSettings,
  ] = useState({
    isEnabled: testSuite?.healthScoreAutomaticThreshold ?? false,
    enabledForAllTests:
      testSuite?.healthScoreAutomaticThresholdEnabledForAll ?? true,
  });

  useEffect(() => {
    if (
      project &&
      testSuite &&
      testSuite.automaticThreshold !== undefined &&
      testSuite.automaticThresholdEnabledForAll !== undefined
    ) {
      setSmartThresholdSettings({
        isEnabled: testSuite.automaticThreshold,
        enabledForAllTests: testSuite.automaticThresholdEnabledForAll,
      });
    }

    if (
      project &&
      testSuite &&
      testSuite.healthScoreAutomaticThreshold !== undefined &&
      testSuite.healthScoreAutomaticThresholdEnabledForAll !== undefined
    ) {
      setHealthScoreSmartThresholdSettings({
        isEnabled: testSuite.healthScoreAutomaticThreshold,
        enabledForAllTests:
          testSuite.healthScoreAutomaticThresholdEnabledForAll,
      });
    }
  }, [testSuite, project]);

  if (error) {
    return (
      <Alert severity="error" data-testid="update-test-suite-error">
        {error.message}
      </Alert>
    );
  }

  if (loading) return <UpdateTestSuiteLoadingView />;

  if (!data || !testSuite || data?.node?.__typename !== "TestSuite") {
    return <Alert severity="info">No data.</Alert>;
  }

  const hasChildren = !!testSuiteChildrenData?.node?.children?.edges.length;

  const templateSettings = (
    <TemplateSettings
      loadError={!!childrenError}
      loading={childrenLoading}
      parent={testSuite.parent}
      childrenTestSuites={testSuiteChildrenData?.node?.children ?? null}
      testSuiteId={testSuite.id}
      testSuiteName={testSuite.name}
      isFetchingMoreChildren={isFetchingMoreChildren}
      loadMoreChildren={fetchMoreChildren}
    />
  );

  function handleStepChange(
    step: string,
    shouldExpandNextStep?: boolean,
  ): StepChangeHandler {
    return (
      _?: React.ChangeEvent<{ value?: unknown }>,
      isExpanded?: boolean,
    ) => {
      setExpandedStep(shouldExpandNextStep || isExpanded ? step : "");
    };
  }

  return (
    <SaveChangesProvider>
      <BuildStatusProvider testSuiteId={testSuite.id}>
        <PageContainer>
          <Typography className={classes.pageTitle}>Edit test suite</Typography>

          <Grid container>
            <Hidden lgUp>
              <Grid item xs={12} style={{ paddingBottom: 16 }}>
                {templateSettings}
              </Grid>
            </Hidden>
            <Grid item lg={9} md={12} xs={12}>
              <Grid
                container
                item
                xs={12}
                alignItems="center"
                className={classes.header}
              >
                <Grid item md={8} lg={8} xl={8} sm={12} xs={12}>
                  <Typography
                    title={testSuite.name}
                    className={classes.title}
                    noWrap
                    data-testid="update-test-suite-title"
                  >
                    {testSuite.name}
                  </Typography>
                </Grid>
                <Grid item md={4} lg={4} xl={4} sm={12} xs={12}>
                  <div
                    className={classes.actions}
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      marginRight: theme.spacing(1),
                    }}
                  >
                    <TestSuiteItemLinks
                      pendoIdSuffix="edit-test-suite"
                      editable={false}
                      clonable={true}
                      deletable={true}
                      id={props.match.params.testSuiteId}
                      name={testSuite.name}
                      isTemplate={
                        !!testSuiteChildrenData?.node?.children?.totalCount
                      }
                    />
                    <Divider
                      orientation="vertical"
                      className={classes.devider}
                    />
                    <RunTestSuiteNowButton
                      testId="run-test-suite-now-top"
                      refetchTestSuite={refetchTestSuite}
                      disabled={
                        (testSuite.testsTotalCount === 0 &&
                          testSuite.healthScoreTestsTotalCount === 0) ||
                        childrenLoading
                      }
                      testSuiteId={props.match.params.testSuiteId}
                      pendoId="auto-run-now-btn-update-testsuite"
                    />
                  </div>
                </Grid>
              </Grid>
              <UpdateTestSuiteSteps
                hasChildren={hasChildren}
                smartThresholdSettings={smartThresholdSettings}
                setSmartThresholdSettings={setSmartThresholdSettings}
                testSuite={testSuite}
                data={data}
                project={project}
                useReportTemplateObject={useReportTemplatesObject}
                handleStepChange={handleStepChange}
                expandedStep={expandedStep}
                refetchTestSuite={refetchTestSuite}
                testSuiteId={testSuite.id}
                absoluteThresholdUpLimit={absoluteThresholdUpLimit}
                healthScoreSmartThresholdSettings={
                  healthScoreSmartThresholdSettings
                }
                setHealthScoreSmartThresholdSettings={
                  setHealthScoreSmartThresholdSettings
                }
              />
            </Grid>
            <Hidden mdDown>
              <Grid item xs={3} style={{ paddingLeft: 16 }}>
                {templateSettings}
              </Grid>
            </Hidden>
          </Grid>
        </PageContainer>
      </BuildStatusProvider>
    </SaveChangesProvider>
  );
});
