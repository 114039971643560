import {
  makeStyles,
  Accordion,
  AccordionSummary,
  Grid,
  AccordionDetails,
  useTheme,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import React, { useState } from "react";
import { TestCollapseDescription } from "../TestCollapseDescription/TestCollapseDescription";
import { Threshold, ReportTemplate } from "./TestCollapse";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { InfoTooltip } from "../InfoTooltip/InfoTooltip";
import { Severity } from "../../../graphql";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(1),
    border: `1px solid ${theme.palette.grey[300]}`,
    boxShadow: "none",
    filter: "none",
  },
  expanded: {
    border: "none",
    padding: "0 1px 0 1px !important",
    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.08)",
    "& .MuiAccordionDetails-root": {
      border: `1px solid ${theme.palette.grey[400]}`,
      borderTop: "none",
    },
  },
  testName: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(17),
    fontWeight: 500,
    marginRight: theme.spacing(1),
    color: theme.palette.grey[800],
  },
  whiteText: {
    color: "#FFFFFF",
  },
  accordionSummaryExpanded: {
    backgroundColor: theme.palette.ultraviolet[500],
    border: `1px solid ${theme.palette.ultraviolet[500]}`,
    borderRight: "none",
    borderLeft: "none",
    borderRadius: "3px 3px 0 0 ",
    "& .MuiAccordionSummary-content": {
      margin: "18px 0px",
    },
  },
  content: {
    margin: "18px 0px",
  },
  accordionSummaryRoot: {
    "&.Mui-expanded": {
      minHeight: "40px",
      maxHeight: "40px",
    },
    padding: "0 15px 0 15px",
    minHeight: "40px",
    maxHeight: "40px",
    transitionDuration: "0s!important",
  },
  expandedIcon: {
    color: theme.palette.grey[700],
    margin: "0 0 0 11px",
    padding: "0",
    "&.Mui-expanded": {
      color: "#FFF",
    },
  },
  accordionDetailsRoot: {
    padding: theme.spacing(2),
    display: "block",
    borderRadius: "0px 0px 3px 3px",
    border: `1px solid ${theme.palette.grey[300]}`,
    borderTop: "none",
  },
  rounded: {
    borderRadius: "3px !important",
  },
}));

interface TestExpansionPanelProps {
  absoluteThresholdUpLimit: number;
  threshold: Threshold;
  severity: Severity;
  reportTemplate: ReportTemplate;
  onChange: (input: {
    threshold: Threshold;
    severity: Severity;
    expanded: boolean;
  }) => void;
  initialExpanded: boolean;
  children: React.ReactNode;
}

export function TestExpansionPanel(
  props: TestExpansionPanelProps,
): JSX.Element {
  const {
    absoluteThresholdUpLimit,
    reportTemplate,
    threshold,
    severity,
    onChange,
    initialExpanded,
  } = props;
  const [isExpanded, setIsExpanded] = useState<boolean>(initialExpanded);
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Accordion
      classes={{
        root: classes.root,
        expanded: classes.expanded,
        rounded: classes.rounded,
      }}
      id={`${reportTemplate.code}-panel`}
      data-testid={`${reportTemplate.code}-panel`}
      data-pendo={`auto-test-suite-edit-choose-tests-test-${reportTemplate.code}`}
      expanded={isExpanded}
      onChange={(_, expanded) => {
        setIsExpanded(expanded);
        onChange({
          severity,
          threshold,
          expanded,
        });
      }}
    >
      <AccordionSummary
        data-testid={`${reportTemplate.code}-summary`}
        expandIcon={<ExpandMoreIcon />}
        classes={{
          root: classes.accordionSummaryRoot,
          expanded: classes.accordionSummaryExpanded,
          content: classes.content,
          expandIcon: classes.expandedIcon,
        }}
      >
        <Grid container alignItems="center" justifyContent="space-between">
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography
              className={clsx(
                classes.testName,
                isExpanded && classes.whiteText,
              )}
              data-testid="test-collapse-name"
            >
              {reportTemplate.name}
            </Typography>
            {reportTemplate.automatorSummary && (
              <InfoTooltip
                data-pendo="auto-tooltip-test-summary-info"
                title={reportTemplate.automatorSummary}
                data-testid="test-collapse-summary-tooltip"
                IconProps={{
                  style: {
                    color: isExpanded
                      ? theme.palette.grey[300]
                      : theme.palette.grey[400],
                  },
                }}
              />
            )}
          </div>
          <div
            data-cy="test-severity-status-label"
            data-testid="test-severity-status-label"
          >
            <TestCollapseDescription
              absoluteThresholdUpLimit={absoluteThresholdUpLimit}
              isExpanded={isExpanded}
              severity={severity}
              threshold={threshold}
              unit={props.reportTemplate.unit}
            />
          </div>
        </Grid>
      </AccordionSummary>
      <AccordionDetails
        data-cy={`${reportTemplate.code}-panel-details`}
        className={classes.accordionDetailsRoot}
      >
        {isExpanded && props.children}
      </AccordionDetails>
    </Accordion>
  );
}
