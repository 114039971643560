import React from "react";
import { makeStyles, Grid } from "@material-ui/core";
import { TestsToDisplayOption } from "./TestSuiteReportListUtils";
import { Link, useParams } from "react-router-dom";
import { Routes } from "../../../_common/routes/routes";

import { useAccountRouteMatch } from "../../../_common/hooks/useAccountRouteMatch/useAccountRouteMatch";
import {
  CogSolid,
  SecondaryTabs,
  SecondaryTab,
  SelectableButtonGroup,
  Typography,
} from "@lumar/shared";
import { GetTestSuiteReportQuery } from "../../../graphql";
import { useURLSearchParams } from "../../../_common/routes/useURLSearchParams";
import { getTestResultConfigs, TestResultConfig } from "./TestResults";
import { MissingDataBox } from "../../../_common/components/MissingDataBox/MissingDataBox";

const useTestSuiteReportStyles = makeStyles((theme) => ({
  grid: {
    marginBottom: theme.spacing(2.25),
  },
  h2: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20),
    color: theme.palette.grey[600],
    marginBottom: 2,
  },
  h3: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(13),
    lineHeight: theme.typography.pxToRem(19.5),
    color: theme.palette.grey[600],
    marginBottom: 4,
  },
  h1: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(23),
    lineHeight: theme.typography.pxToRem(34.5),
    color: theme.palette.grey[900],
    marginBottom: theme.spacing(1),
  },
  link: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(18),
    fontWeight: 500,
    color: theme.palette.primary.main,
    textTransform: "uppercase",
    width: "max-content",
    "&:hover": {
      color: theme.palette.ultraviolet[500],
    },
  },
  linkIcon: {
    fontSize: 18,
    marginRight: 5,
  },
  tabs: {
    maxWidth: 442,
    marginLeft: "auto",
  },
  buttonGroup: {
    marginBottom: theme.spacing(2.5),
  },
  subtitle: {
    alignSelf: "end",
    color: theme.palette.grey[800],
  },
  tabContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(1),
  },
  allTabLabel: {
    minHeight: "29.05px",
    display: "inline-flex",
    alignItems: "center",
  },
}));

export function TestReportList({
  testSuiteReport,
}: {
  testSuiteReport: NonNullable<GetTestSuiteReportQuery["node"]>;
}): JSX.Element {
  const { selectedType } = useParams<{ selectedType?: TestsToDisplayOption }>();
  const params = useURLSearchParams();

  const testResultConfigs = getTestResultConfigs(testSuiteReport);

  const typeParam = params.get("type");
  const selectedTestResultConfig: TestResultConfig | undefined =
    testResultConfigs.find((x) => x.type === typeParam) ?? testResultConfigs[0];

  const [testsToDisplay, setTestsToDisplay] = React.useState(
    selectedType ?? TestsToDisplayOption.ALL,
  );
  const classes = useTestSuiteReportStyles();
  const accountId = useAccountRouteMatch();

  const handleTabChange = (
    _: React.ChangeEvent<{ value?: number }>,
    newValue: TestsToDisplayOption,
  ) => {
    setTestsToDisplay(newValue);
  };

  // GraphQL query uses a filter so that it only returns builds that have a status of 'Running' or 'Queued'
  const isBuildRunning = Boolean(testSuiteReport.testSuite.builds.nodes.length);

  return (
    <>
      {testResultConfigs.length > 1 ? (
        <SelectableButtonGroup
          value={selectedTestResultConfig?.type}
          onValueChange={(value) => {
            params.set("type", value);
            params.navigate();

            const newConfig = testResultConfigs.find((x) => x.type === value);
            if (newConfig && !configHasTestResults(newConfig, testsToDisplay)) {
              setTestsToDisplay(TestsToDisplayOption.ALL);
            }
          }}
          options={testResultConfigs.map((x) => ({
            value: x.type,
            label: `${x.title} (${x.totalCount})`,
          }))}
          className={classes.buttonGroup}
        />
      ) : (
        <Typography className={classes.h1} component="h1">
          {selectedTestResultConfig
            ? `${selectedTestResultConfig.title} (${selectedTestResultConfig.totalCount})`
            : "Test Results"}
        </Typography>
      )}

      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        className={classes.grid}
      >
        <Grid item xs={12} sm={12} md={5} lg={6}>
          <div>
            <Typography
              noWrap
              title={testSuiteReport.testSuite.name}
              className={classes.h2}
              component="h2"
            >
              {testSuiteReport.testSuite.name}
            </Typography>
            <Typography noWrap className={classes.h3} component="h3">
              {testSuiteReport.testSuite.primaryDomain}
            </Typography>

            {!isBuildRunning ? (
              <Link
                to={Routes.EditTestSuite.getUrl({
                  accountId,
                  testSuiteId: testSuiteReport.testSuite.id,
                })}
                data-testid="edit-test-suite-button"
                data-pendo="auto-testsuite-report-edit-testsuite"
                className={classes.link}
              >
                <CogSolid className={classes.linkIcon} />
                <span style={{ paddingTop: 2 }}>Test suite settings</span>
              </Link>
            ) : null}
          </div>
        </Grid>
        <Grid
          item
          xs={testResultConfigs.length > 1 ? 12 : undefined}
          className={classes.tabContainer}
        >
          {testResultConfigs.length > 1 && (
            <Typography
              variant="subtitle2SemiBold"
              className={classes.subtitle}
            >
              {selectedTestResultConfig?.title}
            </Typography>
          )}
          <SecondaryTabs
            value={testsToDisplay}
            onChange={handleTabChange}
            className={classes.tabs}
            colorVariant="grey"
          >
            <SecondaryTab
              label={<span className={classes.allTabLabel}>All</span>}
              value={TestsToDisplayOption.ALL}
              data-testid="all-reports-button"
              data-pendo="auto-report-all-reports-button"
            />
            <SecondaryTab
              value={TestsToDisplayOption.FAIL}
              label={
                <TabLabel
                  firstLine="Fails"
                  secondLine={`${selectedTestResultConfig?.failedCount || 0}`}
                />
              }
              data-testid="fail-reports-button"
              data-pendo="auto-report-fail-reports-button"
              disabled={!selectedTestResultConfig?.failedCount}
            />
            <SecondaryTab
              value={TestsToDisplayOption.WARNING}
              label={
                <TabLabel
                  firstLine="Warnings"
                  secondLine={`${selectedTestResultConfig?.warningCount || 0}`}
                />
              }
              data-testid="warning-reports-button"
              data-pendo="auto-report-warning-reports-button"
              disabled={!selectedTestResultConfig?.warningCount}
            />
            <SecondaryTab
              value={TestsToDisplayOption.PASS}
              label={
                <TabLabel
                  firstLine="Passes"
                  secondLine={`${selectedTestResultConfig?.passedCount || 0}`}
                />
              }
              data-testid="pass-reports-button"
              data-pendo="auto-report-pass-reports-button"
              disabled={!selectedTestResultConfig?.passedCount}
            />
          </SecondaryTabs>
        </Grid>
      </Grid>

      {testSuiteReport.testResults.nodes.length === 0 &&
        testSuiteReport.healthScoreTestResults.nodes.length === 0 && (
          <MissingDataBox
            message="No test results found. Check the test suite configuration to ensure URLs can be crawled."
            dataTestId="build-results-list-info"
          />
        )}

      {selectedTestResultConfig && (
        <selectedTestResultConfig.component
          testSuiteReport={testSuiteReport}
          testsToDisplay={testsToDisplay}
        />
      )}
    </>
  );
}

const TabLabel = (props: { firstLine: string; secondLine: string }) => (
  <>
    {props.firstLine}
    <br />
    {props.secondLine}
  </>
);

function configHasTestResults(
  testResultConfig: TestResultConfig,
  testsToDisplay: TestsToDisplayOption,
): boolean {
  switch (testsToDisplay) {
    case TestsToDisplayOption.FAIL:
      return Boolean(testResultConfig.failedCount);
    case TestsToDisplayOption.WARNING:
      return Boolean(testResultConfig.warningCount);
    case TestsToDisplayOption.PASS:
      return Boolean(testResultConfig.passedCount);
    default:
      return true;
  }
}
